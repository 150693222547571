import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'

import {getMenuRouter} from '@/api/admin'
Vue.use(Vuex)

const state = {
	// 是否获取route
	hasGetRoute: false,
	// routeMap
	routeMap: [],
};
const getters = {
	hasGetRoute: state => state.hasGetRoute,
	routeMap: state => state.routeMap,
};

const mutations = {
	reset(state){

		state.hasGetRoute = false
	},
	setRouteMap(state, routers) {
		state.routeMap = routers
		// 为了防止用户刷新页面导致动态创建的路由失效，将其存储在本地中
		sessionStorage.setItem('routeMap', JSON.stringify(routers));
	},
	setDynamicRouteMap(state, routers) {
		console.log(routers);
		state.hasGetRoute = true
		
			console.log("999999");
		let routerMaps = filterRouter(routers)
		// 最后追加404路由
		routerMaps.push({
			path: '*',
			redirect: '/404',
			component: () => '@/views/404.vue'
		})
		// 追加路由
		// 这块是重点，如果直接使用addRoute是无效的

		routerMaps.forEach(item => {
			router.addRoute(item)
		})
		console.log(router.getRoutes())	
	},



};
const actions = {
	// 获取用户授权动态路由
	async getDynamicRouteOfUser({
		commit
	}) {
		let flag = false
		getMenuRouter().then(res => {
			console.log("路由");
			console.log(res);
			if(res.code==0){
				var Route=res.data.List;
				console.log(Route)
				commit('setRouteMap',Route)
				commit('setDynamicRouteMap',Route)
				flag = true
			}else{
				  console.log(err)
			}
		})
		
		return flag
	},
	// 刷新重置路由
	updateRouteOfUser({
		commit
	}, routerMap) {
		commit('setDynamicRouteMap', routerMap)
	},
};
// handle views
const loadView = (viewPath) => {
    return () => import('@/views/'+viewPath+'.vue')
}

// Handle routers
const filterRouter = (routers) => {
    return routers.filter((router) => {
   		// 区分布局与视图文件，因为加载方式不同
        if (router.component === 'Layout') {
            router.component =() => import('@/views/index/Main.vue')
        }else if(router.component != ''){
		    router.component = loadView(router.component)
		}
		else {
           router.component=null
        
        }
		router.meta={title:router.title}
        // 删除路由记录中的无用字段：这段是本示例与后台协商的，但在vue-router中不被支持的字段信息，可忽略
        if (!router.redirect || !router.redirect.length) { delete router.redirect }
        // 判断是否存在子路由，并递归调用自己
        if(router.children && router.children.length) {
            router.children = filterRouter(router.children)
        }
        return true
    })
} 

const store = {
	// namespaced用于在全局引用此文件里的方法时标识这一个的文件名，使得让人明白这些数据来自哪个仓库
	// 即当你需要在别的文件里面使用子仓库(mapStates、mapGetters、mapActions)时，里面的方法需要注明来自哪一个模块的方法
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}
export default store;
