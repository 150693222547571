import Vue from 'vue'
import { MessageBox,Message } from "element-ui";
import i18n from "../locale/i18n.js"
Vue.component(Message.name,Message)
Vue.component(MessageBox.name,MessageBox)




export const $MessageBox=(callback,test=i18n.t('tip-confirm'),)=>{
    MessageBox.confirm(test, i18n.t('tip'), {
        confirmButtonText:i18n.t('tip-yes'),
        cancelButtonText: i18n.t('tip-cancel'),
      }).then(() => {
			callback()
      }).catch(() => {
        //rej()      
      });
}