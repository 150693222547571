//home.js
import {login} from '@/api/user.js'
import Vue from 'vue'
import Vuex from 'vuex'
import {message} from 'element-ui';
import {title} from '@/config'
import { resetRouter } from '@/router'

Vue.use(Vuex)

const state = {
	userInfo: {}, // 登录成功后的用户信息
	// 用户状态相关 
	userState:JSON.parse(sessionStorage.getItem('userState')) || {ut: '', isLogin: false, userType: null},
	username:'',
	avatar:'',
	
};
const getters = {
	getUsers:(state)=> state.userInfo.msg, //获取访问该页面的用户;
	username: (state) => state.username,
	avatar: (state) => state.avatar,
};
const mutations = {
	// 用户注销
	loginOut(state) {
		// state.userInfo = {}
		// state.username="";
		// state.avatar="";
		// state.userState ={tk:'',isLogin: false, userType: null}
		sessionStorage.clear()
	},
	// 用户登录
	setUserInfo(state, data) {
		state.userInfo = data;
		state.username = data.msg;
		// 将登录的用户信息存储一份到 sessionStorage，防止刷新页面 vuex 中的用户信息清除，sessionStorage 不能存储对象，转为字符串存储
		sessionStorage.userInfo = JSON.stringify(data)
		
	},
	setUserState(state,data) {
	     state.userState =data
	     sessionStorage.setItem('userState', JSON.stringify(state.userState))
	},
	

};
const actions = {
	// 用户注销
	loginOut({commit}) {
		commit('loginOut')
		resetRouter()
	},
	//登录
	async login({
		commit
	}, userInfo) {
		const data = await login(userInfo)
		console.log(data)
		if (data.code==0) {
			commit('setUserInfo', data)
			commit('setUserState', {ut:data.sessionkey,isLogin: true, userType:''})
			const hour = new Date().getHours()
			const thisTime =hour < 8 ?'早上好' :hour <= 11 ?'上午好' :hour <= 13 ?'中午好' :hour < 18 ?'下午好' :'晚上好'
			Vue.prototype.$message.info(`欢迎登录${title},${thisTime}！`)
			return data;
		} else {
			return data;
		}


		// Vue.prototype.$baseMessage(
		//   `登录接口异常，未正确返回${tokenName}...`,
		//   'error'
		// )

	},

};
// 注意和仓库的区别
const store = {
	// namespaced用于在全局引用此文件里的方法时标识这一个的文件名，使得让人明白这些数据来自哪个仓库
	// 即当你需要在别的文件里面使用子仓库(mapStates、mapGetters、mapActions)时，里面的方法需要注明来自哪一个模块的方法
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
export default store;
//配置插件支持

