import request from '@/utils/request.js'
export function getMenuList(params) {//接口
  return request({
      url:'/Company/getMenuList.ashx',
      method: 'get',
      params
  })
}
export function postMenuList(data) {//接口
  return request({
      url:'/Company/getMenuList.ashx',
      method: 'post',
      data
  })
}

export function getMenuRouter() {//获取路由菜单
  return request({
      url:'/Admin/GetMenu',
      method: 'get',
  })
}
export function getRoleList(params) {//角色接口
  return request({
      url:'/Company/getRoleList.ashx',
      method: 'get',
	  params
  })
}
export function postRoleList(data) {//角色接口
  return request({
      url:'/Company/getRoleList.ashx',
      method: 'post',
	  data
  })
}

export function getAdmin_of_RoleNull () {//获取角色为空的用户
  return request({
      url:'/Company/getAdminList.ashx',
      method: 'post',
	  data:{ action: "getAdminList", NULLRole:0 }
  })
}
export function getRoleMenu(RoleID) {//角色菜单接口
  return request({
      url:'/Company/getRoleList.ashx',
      method: 'get',
	  params:{action: "GetMenu", RoleID:RoleID}
  })
}

export function UpdateJurisdiction(RoleID,MenuID,btnval) {//角色菜单按钮权限
  return request({
      url:'/Company/getJurisdiction.ashx',
      method: 'post',
	  data:{action: "UpdateJurisdiction", ADID:RoleID,MenuID:MenuID,btnval:btnval}
  })
}
export function AddJurisdictionZs(RoleID,MenuID) {//角色添加菜单权限
  return request({
      url:'/Company/getJurisdiction.ashx',
      method: 'post',
	  data:{action: "AddJurisdictionZs", ADID:RoleID,MenuID:MenuID}
  })
}
export function DeleteJurisdiction(RoleID,MenuID) {//角色添加菜单权限
  return request({
      url:'/Company/getJurisdiction.ashx',
      method: 'post',
	  data:{action: "DeleteJurisdiction", ADID:RoleID,MenuID:MenuID}
  })
}

