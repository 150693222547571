import Vue from 'vue'
import axios from 'axios'
import {
	baseURL,
	contentType,
	debounce,
	header,
	requestTimeout,
	successCode,
} from '@/config/index.js'
import router from '@/router'
import store from '@/store/index.js'
import qs from 'qs'
import {
	isArray
} from '@/utils/validate'

let loadingInstance

const handleCode = (success, msg) => {
	switch (success) {
		case 401:
			Vue.prototype.$message.error(msg || '登录失效')
			store.dispatch('user/resetAll').catch(() => {})
			break
		case 403:

			this.$Router.push({
				name: 'home',
				params: {}
			})
			break
		default:
			break
	}
}

const instance = axios.create({
	baseURL: process.env.NODE_ENV === 'development'?'/api':baseURL,
	timeout: requestTimeout,
	headers: {
		'Content-Type': contentType,
	},
})


// 请求拦截器
instance.interceptors.request.use(config => {
		//发请求前做的一些处理，数据转化，配置请求头，设置token,设置loading等，根据需求去添加
		const userState = JSON.parse(sessionStorage.getItem("userState"))
		console.log(userState);
		console.log(config)
		console.log(123)
		if (userState && userState.ut != '') { //判断token是否存在
		// instance.defaults.headers.common['Authorization']=userState.ut;
		 // config.headers.Authorization = userState.ut;
		//         console.log('interceptors config=',config)
		config.headers.setAuthorization(userState.ut,false);
		// config.headers = [function(data) {
		// 	data.append('Authorization', userState.ut);
		// }]
		 // typeof config.headers.set === 'function' &&
		 //        config.headers.set('Authorization', userState.ut)
		  // config.headers.set('Authorization', userState.ut)
		  // config.headers['Authorization']= userState.ut;
		  
		}
		if (config.method === "get") {
			console.log(config.params)
			if (config.params) {
				config.params['route'] = router.app._route.name;
			}else {
		console.log(456)
				
			}


		} else if (config.method === "post") {
			console.log(config.data)
			if (config.data instanceof FormData) { //判断是FormData
				config.transformRequest = [function(data) {
					data.append('route', router.app._route.name);
					return data
				}]

			} else {
				config.data['route'] = router.app._route.name;
			}

		}



		  return config



		// console.log(config)
	},
	(error) => {
		return Promise.reject(error)
	}
)
instance.interceptors.response.use(
	(response) => {
		if (loadingInstance) loadingInstance.close()
		const res = response;
		const {
			data,
			config
		} = response
		const {
			success,
			msg
		} = data
		return data
		// 操作正常Code数组
		// const codeVerificationArray = isArray(successCode) ?
		// 	[...successCode] :
		// 	[...[successCode]]
		// 是否操作正常

		// if (codeVerificationArray.includes(code)) {
		// if (success == successCode) {
		// 	return data
		// } else {
		// 	handleCode(success, msg)
		// 	Promise.reject(
		// 		'vue-admin-beautiful请求异常拦截:' +
		// 		JSON.stringify({
		// 			url: config.url,
		// 			success,
		// 			msg
		// 		}) || 'Error'
		// 	)
		// 	return data
		// }
	},
	(error) => {
		if (loadingInstance) loadingInstance.close()
		const {
			response,
			message
		} = error
		if (error.response && error.response.data) {
			const {
				status,
				data
			} = response
			handleCode(status, data.msg || message)
			return Promise.reject(error)
		} else {
			let {
				message
			} = error
			if (message === 'Network Error') {
				message = '后端接口连接异常'
			}
			if (message.includes('timeout')) {
				message = '后端接口请求超时'
			}
			if (message.includes('Request failed with status code')) {
				const code = message.substr(message.length - 3)
				message = '后端接口' + code + '异常'
			}
			Vue.prototype.$message.error(message || `后端接口未知异常`, 'error')
			return Promise.reject(error)
		}
	}
)

export default instance
