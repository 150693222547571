<template>
	<div id='app'>
		<router-view />
	</div>
</template>



<script>
	import i18n from './locale/i18n.js'
	export default {
		name: 'App',
		mounted() {
			let lang = localStorage.getItem('lang')
			if (lang == 'zh') {
				this.$i18n.locale = 'zh';
			} else {
				this.$i18n.locale = 'en';
				this.locale = null;
			}

			let _this = this;
			window.onresize = function() {
				// 定义窗口大小变更通知事件
				console.log(_this.$store.state.layout.screenWidth)
				_this.$store.state.layout.screenWidth = document.documentElement.clientWidth; //窗口宽度
			}


		},
		onLaunch: function() {
			console.warn('当前组件仅支持 uni_modules 目录结构 ，请升级 HBuilderX 到 3.1.0 版本以上！')
			console.log('App Launch')
		},
		onShow: function() {
			console.log('App Show')
		},
		onHide: function() {
			console.log('App Hide')
		}
	}
</script>

<style lang="scss">
	// 设置整个项目的背景色
	page {
		background-color: #f5f5f5;
	}

	html,
	body,
	h3,
	p {
		margin: 0;
		padding: 0
	}

	#app {
		min-width: 300px
	}

	.el-tabs__nav .el-tabs__item:nth-child(1) span {
		display: none !important;
	}

	.el-tabs--card>.el-tabs__header {
		background-color: white;
	}

	.demo-drawer__content form {
		flex: 1;
	}

	.demo-drawer__footer {
		display: flex;
	}

	.demo-drawer__footer button {
		flex: 1;
	}

	.demo-drawer__content {
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	.el-drawer__body {
		padding: 20px;
	}
	.el-select-dropdown__wrap  .el-scrollbar__wrap{
		margin-bottom:0px
	}
	.el-scrollbar__wrap{
		margin-bottom:0px
	}
</style>
