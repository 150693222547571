import request from '@/utils/request.js'
export async function login(data) {
  return request({
      url:'/Admin/Login',//模拟数据接口
      method: 'post',
      data
  })
}
export async function getMenu() {
  return request({
      url:'/Menu/getMenuList.ashx',//模拟数据接口
      method: 'get'
  })
}


export function userInfo(query) {
	return request({
		url: '/Users/UserInfo', //模拟数据接口
		method: 'post',
		data: query
	})
}

export function userDetail(query) {
	return request({
		url: '/Users/UserDetail', //模拟数据接口
		method: 'post',
		data: query
	})
}

export function changeRole(query) {
	return request({
		url: '/Users/ChangeRole', //模拟数据接口
		method: 'post',
		data: query
	})
}


