import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index.js'

Vue.use(VueRouter)

// 解决重复点击路由报错的BUG
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err)
}
const routes =[
  	{
  		path: '/Login',
  		name: 'Login',
  		component: () => import('../views/Login/Login.vue')
  	},
  	{
  		path: '/',
		component: () => import('../views/index/Main.vue'),
		redirect:'/home',
  		children: [{
  				path: 'home', //默认显示页路径
  				name: '首页',
  				component: () => import('../views/index/index.vue')
  		},
		
  		]
	},
    ]
const staticRouterMap = [
	'Login',

]
const router = new VueRouter({
	// mode: 'history',
	base: process.env.BASE_URL,
	routes//路由表
});
let load = 0
//全局路由前置守卫
router.beforeEach((to, from, next) => {
	console.log(to)
	// 如果用户访问的是登录页，直接放行
	// if (to.name === 'home') {
	// 	// 获取用户信息 token
	// 	const tokenStr = window.sessionStorage.getItem('userInfo')
	// 	if (!tokenStr) {
	// 		// 运行到这里说明你的tokenStr是空，强制跳转到登录界面
	// 		return next('/Login')
	// 	}
	// }hasGetRoute
	
	
	const userState  = JSON.parse(sessionStorage.getItem("userState"))
		if(to.name === 'Login'){
			next()
		}else if(!userState || !userState.isLogin) {
			// 没有登录
				// 如果前往页面非公共路由，则跳转至首页
				if (staticRouterMap.indexOf(to.name) < 0) {
					next({name: 'Login'})
				} else {
					next()
				}
	
		} else {
		console.log('已经登录')
			// 登录
			// 已经存在路由列表: 注意刚登陆成功第一次调转route时相应store数据还未更新
		const routeMap = JSON.parse(sessionStorage.getItem('routeMap'))
		
			if(load === 0 &&routeMap) {
				console.log('刷新页面')
				load++
	            // 刷新页面且有route记录数据，可再次追加动态路由
				console.log(routeMap)
				store.dispatch('routers/updateRouteOfUser', routeMap)
				next({...to, replace: true})
				//next('/')
				
			} else {
				next()
			}
		}
	
});
// 全局路由后置守卫
router.afterEach((to, from) => {
	console.log('跳转结束')
})

export function resetRouter() {
  location.reload()
}

export default router